import React, { Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../../shared/actions";
import moment from "moment";
import Modal from "react-modal";
import EditAddonChargeModal from "../../../../Modals/EditAddonChargeModal";

function AddOnDetails(props) {
	const add_on = props.data;
	const [editAddonModalIsOpen, set_editAddonModalIsOpen] = useState(false);

	function getDescription(description) {
		if (description.length > 34) {
			return (
				<div>
					<div className="col data-set">
					<span>
						<strong>
							Add-On Description:
						</strong>
					</span>
					</div>
					<div className="col data-set">
					<span>
						{add_on.description}
					</span>
					</div>
				</div>
			)
		} else {
			return (
				<div className="col data-set">
					<span>
						<strong>
							Add-On Description:
						</strong>
					</span>
					<span>
						{add_on.description}
					</span>
				</div>
			)
		}
	}

	function getName(name) {
		if (name.length > 40) {
			return (
				<div>
					<div className="col data-set">
					<span>
						<strong>
							Add-On Name:
						</strong>
					</span>
					</div>
					<div className="col data-set">
					<span>
						{add_on.name}
					</span>
					</div>
				</div>
			)
		} else {
			return (
				<div className="col data-set">
					<span>
						<strong>
							Add-On Name:
						</strong>
					</span>
					<span>{add_on.name}</span>
				</div>
			)
		}
	}

	return (
		<Fragment>
			<td colSpan={12} style={{ padding: "10px" }}>
				<div className="columns">
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__body">
									<div className="row centered-items hr-margin-24-top hr-margin-24-bottom">
										<div className="col auto">
											<h2 className="black-text no-wrap">
												{add_on.name.toUpperCase()}{" "}
												DETAILS
											</h2>
										</div>
									</div>

									<div className="row">
										<div className="col is-12-of-24 data-set__container">
											{getName(add_on.name)}
											{getDescription(add_on.description)}
											<div className="col data-set">
												<span>
													<strong>
														Is Add-On Optional?:
													</strong>
												</span>
												<span>
													{add_on.is_optional === true
														? "Yes"
														: "No"}
												</span>
											</div>
											{add_on.is_optional === true && (
												<div className="col data-set">
													<span>
														<strong>
															Is this a Donation?:
														</strong>
													</span>
													<span>
														{add_on.is_donation ===
														true
															? "Yes"
															: "No"}
													</span>
												</div>
											)}
											{(add_on.is_donation === true && add_on.is_optional === true) && (
												<div className="col data-set">
													<span>
														<strong>
															Provide Multiple
															Options?:
														</strong>
													</span>
													<span>
														{add_on.is_multiple_options ===
														true
															? "Yes"
															: "No"}
													</span>
												</div>
											)}
											<div className="col data-set">
												<span>
													<strong>Amount:</strong>
												</span>
												<span>{add_on.is_multiple_options === true ? 'Multiple Options' : add_on.amount}</span>
											</div>
										</div>

										<div className="col is-8-of-24 data-set__container">
											<div className="col data-set second">
												<span>
													<strong>
														Date Created:
													</strong>
												</span>
												<span>
													{moment(
														new Date(
															add_on.created_at
														),
														"YYYY/MM/DD HH:mm:ss"
													).format("MM/DD/YYYY")}
												</span>
											</div>
											<div className="col data-set second">
												<span>
													<strong>Status:</strong>
												</span>
												<span>
													{add_on.is_enabled === true
														? "Active"
														: "Inactive"}
                        </span>
											</div>
											<div className="col data-set second">
												<span>
													<strong>
														Number of Times Used:
													</strong>
												</span>
												<span>{add_on.times_used}</span>
											</div>
										</div>

										{props.canEditAssociation && (
											<div className="col auto push-right left">
												<div
													className="row"
													style={{
														display: "flex",
														justifyContent: "end",
													}}
												>
													<button
														className="btn fill green"
														onClick={() => {
															set_editAddonModalIsOpen(
																true
															);
														}}
													>
														Edit
													</button>
												</div>
												<div
													className="row"
													style={{
														display: "flex",
														justifyContent: "end",
													}}
												>
													<div
														className="row"
														style={{
															display: "flex",
															justifyContent:
																"end",
														}}
													>
														<div className="col auto">
															<button
																className="btn fill green"
																onClick={() => {
																	props.exportAddOn();
																}}
															>
																Export to CSV
															</button>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>

									<hr
										style={{
											border: "0.5px solid #cccccc",
											marginTop: "25px",
											marginBottom: "25px",
										}}
									></hr>
								</div>
							</div>
						</div>
					</div>
				</div>
			</td>

			<Modal
				isOpen={editAddonModalIsOpen}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={false}
				shouldFocusAfterRender={false}
			>
				<EditAddonChargeModal
					selected_addon={add_on}
					reload_addons={props.reload_addons}
					close_modal={() => {
						set_editAddonModalIsOpen(false);
					}}
					association_id={props.associationId}
					operationType={"Edit"}
				/>
			</Modal>
		</Fragment>
	);
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(null, mapDispatchToProps)(AddOnDetails);
