import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import SharedService from '../../services/api/shared';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {
	required,
	requiredSelect,
	isEmailAddress,
	alphaNumeric,
	isUrl,
	isNumber,
	maxLength
} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import diff from 'object-diff';
import AssociationService from "../../services/api/association";
import {addAlert} from '../shared/actions'
import frontendProviders from "../shared/frontendProvider";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import usgaTmAccountManager from "../shared/usgaTmAccountManager";
import regionalAffairsDirectors from "../shared/regionalAffairsDirectors";


class EditAssocAccountForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			timeZones: [],
			allied_assn: false,
			command_user: false,
			handicap_program_lic: false,
			course_rating_lic: false,
			status: false
		}
	}

	componentDidMount() {

		this.props.initialize({
			...this.props.assoc,
			...{
				timezone:{
					label: this.props.assoc.timezone,
					value: this.props.assoc.timezone
				},
				allied_assn: this.props.assoc.allied_assn,
				frontend_provider:this.props.assoc.frontend_provider ? {label: this.props.assoc.frontend_provider, value: this.props.assoc.frontend_provider} : null,
				status: this.props.assoc.status === "Active" ? true : false,
				usga_tm_account_manager: {label: this.props.assoc.usga_tm_account_manager, value: this.props.assoc.usga_tm_account_manager},
				regional_affairs_director: {label: this.props.assoc.regional_affairs_director, value: this.props.assoc.regional_affairs_director}
			}});

		SharedService.getTimezones()
			.then(res => {

				let timeZones = [];
				res.time_zones.map(timezone => {
					timeZones.push({label: timezone.display, value: timezone.code});
					return null;
				});

				timeZones.push({label: this.props.assoc.timezone, value: this.props.assoc.timezone});

				this.setState({
					timeZones,
					allied_assn: this.props.assoc.allied_assn,
					command_user: this.props.assoc.command_user,
					status: this.props.assoc.status === "Active" ? true : false,
					course_rating_lic: this.props.assoc.course_rating_lic,
					handicap_program_lic: this.props.assoc.handicap_program_lic
				});
			})
			.catch(err => {
				console.error(err)
			});


		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));
		data.timezone = values.timezone.value;
		if (data.frontend_provider) data.frontend_provider = data.frontend_provider.value;
		if (data.regional_affairs_director) data.regional_affairs_director = data.regional_affairs_director.value;
		if (data.usga_tm_account_manager) data.usga_tm_account_manager = data.usga_tm_account_manager.value;
		data.status = data.status ? "Active" : "Inactive";
		data = diff(props.assoc, data);

		return AssociationService.editPrimary(props.associationId, data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Association has been successfully edited'
				});
				props.close(res);
			})
			.catch(err=>{console.error(err)});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
            <Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
                    <div className="col is-full">
                        <span className="label" >Allied Golf Association ? <span>*</span></span>
                        <div className="radio__container">
							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {allied_assn: !prevState.allied_assn};
										});
									}
								}
								checked={this.state.allied_assn}
								id="allied_assn_yes"
								value={true}
								name={'allied_assn'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
                            <label htmlFor="allied_assn_yes">Yes</label>
							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {allied_assn: !prevState.allied_assn};
										});
									}
								}
								checked={!this.state.allied_assn}
								id="allied_assn_no"
								value={false}
								name={'allied_assn'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
                            <label htmlFor="allied_assn_no">No</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="name">Association Name <span>*</span></label>
						<Field component={InputField} validate={[required, maxLength([60, 'The Association Name cannot exceed 60 characters.'])]} type="text" name="name" id="name" />
					</div>
                </div>

                <div className="row">
                    <div className="col is-1-of-3">
                        <label htmlFor="short_name">Short Name</label>
						<Field component={InputField} format={value => value ? value.slice(0,8) : ""} validate={[alphaNumeric]} type="text" name="short_name" id="short_name" />
                    </div>
                    <div className="col is-1-of-3">
                        <label htmlFor="parent-golf-federation">Parent Golf Federation <span>*</span></label>
                        <Select className="react-select-container" classNamePrefix="react-select"  value={{value:'United States Golf Association',label:'United States Golf Association'}}/>
                    </div>
					<div className="col is-1-of-3">
						<label htmlFor="course_rating_id">Course Rating Id</label>
						<Field component={InputField} validate={[isNumber]} type="text" name="course_rating_id" id="course_rating_id" />
					</div>
                </div>

                <div className="row">
					{/* not needed now */}
                    {/*<div className="col">*/}
					{/*	<Field*/}
					{/*		onClick={*/}
					{/*			() => {*/}
					{/*				this.setState((prevState) => {*/}
					{/*					return {command_user: !prevState.command_user};*/}
					{/*				});*/}
					{/*			}*/}
					{/*		}*/}
					{/*		checked={this.state.command_user}*/}
					{/*		id="command_user"*/}
					{/*		value={true}*/}
					{/*		name={'command_user'}*/}
					{/*		component={'input'}*/}
					{/*		type="checkbox"*/}
					{/*	/>*/}
                    {/*    <label htmlFor="command_user">Command User</label>*/}
                    {/*</div>*/}
					{/* not needed now */}
                    {/*<div className="col ">*/}
					{/*	<Field*/}
					{/*		onClick={*/}
					{/*			() => {*/}
					{/*				this.setState((prevState) => {*/}
					{/*					return {handicap_program_lic: !prevState.handicap_program_lic};*/}
					{/*				});*/}
					{/*			}*/}
					{/*		}*/}
					{/*		checked={this.state.handicap_program_lic}*/}
					{/*		id="handicap_program_lic"*/}
					{/*		value={true}*/}
					{/*		name={'handicap_program_lic'}*/}
					{/*		component={'input'}*/}
					{/*		type="checkbox"*/}
					{/*	/>*/}
                    {/*    <label htmlFor="handicap_program_lic">Handicap Program Lic.</label>*/}
                    {/*</div>*/}
					{/* not needed now */}
                    {/*<div className="col ">*/}
					{/*	<Field*/}
					{/*		onClick={*/}
					{/*			() => {*/}
					{/*				this.setState((prevState) => {*/}
					{/*					return {course_rating_lic: !prevState.course_rating_lic};*/}
					{/*				});*/}
					{/*			}*/}
					{/*		}*/}
					{/*		checked={this.state.course_rating_lic}*/}
					{/*		id="course_rating_lic"*/}
					{/*		value={true}*/}
					{/*		name={'course_rating_lic'}*/}
					{/*		component={'input'}*/}
					{/*		type="checkbox"*/}
					{/*	/>*/}
                    {/*    <label htmlFor="course_rating_lic">Course Rating Lic.</label>*/}
                    {/*</div>*/}
					<div className="col ">
						<Field
							onClick={
								() => {
									this.setState((prevState) => {
										return {status: !prevState.status};
									});
								}
							}
							checked={this.state.status}
							id="status"
							value={true}
							name={'status'}
							component={'input'}
							type="checkbox"
						/>
						<label htmlFor="status">Active</label>
					</div>
                </div>

				<div className="row">
					<div className="col is-full">
						<label>Front End Provider <span>*</span></label>
						<Field name={'frontend_provider'} id={'frontend_provider'} validate={[required, requiredSelect]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={frontendProviders}/>
					</div>
				</div>

                <div className="row">
                    <div className="col is-full">
                        <label>Timezone <span>*</span></label>
						<Field name={'timezone'} id={'timezone'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.timeZones}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <label htmlFor="telephone">Phone <span>*</span></label>
						<Field component={InputField} validate={[required]} type="text" name="telephone" id="telephone" />
                    </div>
                    <div className="col is-1-of-2">
                        <label htmlFor="email">Email <span>*</span></label>
						<Field component={InputField} type="text" validate={[required, isEmailAddress]} name="email" id="email" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="web_address">Website</label>
						<Field component={InputField} type="text" validate={[isUrl]} name="web_address" id="web_address" />
                    </div>
                </div>

				{this.props.join_and_renew_enabled &&
					<div className="row">
						<div className="col">
							<label htmlFor="web_address_join_and_renew_page">Website Join & Renew Page</label>
							<Field component={InputField} type="text" validate={[isUrl]} name="web_address_join_and_renew_page"
										 id="web_address_join_and_renew_page"/>
						</div>
					</div>
				}

				<div className="row">
					<div className="col is-full">
						<label htmlFor="usga_tm_account_manager">USGA TM Account Manager <span>*</span></label>
						<Field name="usga_tm_account_manager"
							   id="usga_tm_account_manager"
							   component={SelectInput}
							   validate={[requiredSelect]}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={usgaTmAccountManager}/>

					</div>
				</div>

				<div className="row">
					<div className="col is-full">
						<label htmlFor="regional_affairs_director">Regional Affairs Director <span>*</span></label>
						<Field name="regional_affairs_director"
							   id="regional_affairs_director"
							   component={SelectInput}
							   validate={[requiredSelect]}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={regionalAffairsDirectors}/>

					</div>
				</div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={()=>this.props.close()}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>
				</form>
            </Fragment>
		);
	}
}

EditAssocAccountForm = reduxForm({
	form: 'editAccountForm',
	destroyOnUnmount: true
})(EditAssocAccountForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAssocAccountForm));
