import React, {Component, Fragment} from 'react';

import EditAssocAccountForm from '../Forms/EditAssocAccountForm';

export default class EditAssocAccountModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Association Account</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditAssocAccountForm assoc={this.props.assoc.profile} associationId={this.props.associationId} close={(data)=>this.props.closeModal(data)} join_and_renew_enabled={this.props.join_and_renew_enabled}/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
