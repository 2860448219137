import React, {Component, Fragment} from 'react';

import EditGolfersStatusForm from '../Forms/EditGolfersStatusForm';
import SuccessModal from "./SuccessModal";
import moment from "moment";

export default class EditClubStatusModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			success: false,
			now: false
		}
	}

	getActivateMessage() {
		let message = ''
		if (this.state.data.status === 'started') {
			message =  `${this.state.data.successfully_activated_golfers} ${this.state.data.successfully_activated_golfers > 1 ? 'golfers have' : 'golfer has'} been`
		} else if (this.state.data.status === 'enqueued') {
			message = `${this.state.data.successfully_activated_golfers} ${ this.state.data.successfully_activated_golfers > 1 ? "golfers" : "golfer"} will be`
		}
		return message;
	}

	getInactivateMessage() {
		let message = ''
		if (this.state.data.status === 'started') {
			message =  `${this.state.data.successfully_inactivated_golfers} ${this.state.data.successfully_inactivated_golfers > 1 ? 'golfers have' : 'golfer has'} been`
		} else if (this.state.data.status === 'enqueued') {
			message = `${this.state.data.successfully_inactivated_golfers} ${ this.state.data.successfully_inactivated_golfers > 1 ? "golfers" : "golfer"} will be`
		}
		return message;
	}

	render() {
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">{this.props.type && (this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1))} Golfers</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								onMainAction={() => {
									this.props.closeModal('refresh')
								}}
								warning={this.state.data.unsuccessfully_activated_golfers > 0 || this.state.data.unsuccessfully_inactivated_golfers > 0}
								warningTitle={(this.state.data.successfully_activated_golfers > 0 || this.state.data.successfully_inactivated_golfers > 0) ? 'Some records need your review' : 'Please Review'}
								message={
									this.props.type ==="activate" ?
									<Fragment>
										{this.state.data.successfully_activated_golfers !== 0 && <p>{this.getActivateMessage()} <span className={'underline'}>activated</span> {this.state.data.status === 'started' ? 'successfully' : `on ${moment(this.state.data.from_date).format("MM/DD/YYYY")}`}.</p>}
										{this.state.data.unsuccessfully_activated_golfers !== 0 && <p><span className={'validation__message is-error'}>{this.state.data.unsuccessfully_activated_golfers} {this.state.data.unsuccessfully_activated_golfers > 1 ? "golfers" : "golfer"} could not be <span className={'underline'}>activated</span>.</span></p>}
									</Fragment>
									:
									<Fragment>
										{this.state.data.successfully_inactivated_golfers !== 0 && <p>{this.getInactivateMessage()} <span className={'underline'}>inactivated</span> {this.state.data.status === 'started' ? 'successfully' : `on ${moment(this.state.data.from_date).format("MM/DD/YYYY")}`}.</p>}
										{this.state.data.unsuccessfully_inactivated_golfers !== 0 && <p><span className={'validation__message is-error'}>{this.state.data.unsuccessfully_inactivated_golfers} {this.state.data.unsuccessfully_inactivated_golfers > 1 ? "golfers" : "golfer"} could not be <span className={'underline'}>inactivated</span>.</span></p>}
									</Fragment>

								}
								closeModal={() => {
									this.props.closeModal('refresh')
								}}
								mainLabel={"OK"}
								specialButton={this.state.data.download_url ? this.state.data.download_url : null}
							/>
							:
							<EditGolfersStatusForm  type={this.props.type} isAllSelected={this.props.isAllSelected} golfers={this.props.golfers} onSuccess={(data, now)=>this.setState({success: true, data, now})} filters={this.props.filters} associationId={this.props.assoc} clubId={this.props.club} close={(data)=>this.props.closeModal(data)}/>}

					</div>

				</div>

			</Fragment>
		);
	}
}
