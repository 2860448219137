import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {compose} from "redux";
import PrimaryAssoc from './PrimaryAssoc';
import AccountSetupAssoc from './AccountSetupAssoc';
import ManageUsersAssoc from './ManageUsersAssoc';
import ClubBillingReportAssoc from "./ClubBillingReportAssoc";
import DigitalProfilesAssoc from "./DigitalProfilesAssoc";
import AlertsAssoc from "./AlertsAssoc";
import AgedOutGolfers from "./AgedOutGolfers"


class Account extends Component {
	constructor(props) {
	super(props);
	this.state = {
		supportedAssociations: ["132"]
	};
}

	render() {
    	return (
            <Fragment>

                <div className="vertical-tabs__container row">
                    <ul className="vertical-tabs col">
                        <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/primary`}>Primary</NavLink></li>
						{process.env.REACT_APP_TYPE !== "hv" && <Fragment>
							<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/account-setup`}>Account Setup</NavLink></li>
						</Fragment>}
                        <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/manage-users`}>Manage Users</NavLink></li>
              <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/club-billing-report`}>Club Billing Report</NavLink></li>
              <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/manage-aged-out-golfers`}>Manage Aged-Out Golfers</NavLink></li>
						{ (process.env.REACT_APP_TYPE === "staging" ? ((this.props.userType === "super-admin" || this.props.userType === "association-admin" || this.props.userType === "association-read-only") && this.state.supportedAssociations.filter(id => id === this.props.associationId).length !== 0) : (this.props.userType === "super-admin" || this.props.userType === "association-admin" || this.props.userType === "association-read-only"))  &&
							<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/digital-profiles`}>Digital Profiles</NavLink></li>
						}
            <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/alerts`}>Alert Management</NavLink></li>
                    </ul>
                    <div className="vertical-tabs__panel col">
                        <Switch>
                            <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/primary`}/>
                            <Route path={`${this.props.match.path}/primary`} component={(props) =>
								<PrimaryAssoc canEditAssociation={this.props.canEditAssociation}
															associationId={this.props.associationId}
															join_and_renew_enabled={this.props.join_and_renew_enabled}/>}/>
                {process.env.REACT_APP_TYPE !== "hv" && <Route path={`${this.props.match.path}/account-setup`} render={() =>
									<AccountSetupAssoc canEditAssociation={this.props.canEditAssociation}
													   association={this.props.association}
													   updateAssociation={()=>this.props.update()}
                    associationId={this.props.associationId}
                    update_join_renew={this.props.update_join_renew}
                    join_and_renew_enabled={this.props.join_and_renew_enabled}
                  />} />}
                            <Route path={`${this.props.match.path}/manage-users`} component={(props) =>
								<ManageUsersAssoc {...props}
												  canEditAssociation={this.props.canEditAssociation}
												  associationId={this.props.associationId}/>}/>
							<Route path={`${this.props.match.path}/club-billing-report`} component={(props) =>
								<ClubBillingReportAssoc {...props}
												  canEditAssociation={this.props.canEditAssociation}
													userType={this.props.userType}
												  associationId={this.props.associationId}
                    join_and_renew_enabled={this.props.join_and_renew_enabled} />} />
                <Route path={`${this.props.match.path}/manage-aged-out-golfers`} component={(props) =>
								<AgedOutGolfers {...props}
												  canEditAssociation={this.props.canEditAssociation}
												  associationId={this.props.associationId}
                          join_and_renew_enabled={this.props.join_and_renew_enabled}/>}/>
												{ (process.env.REACT_APP_TYPE === "staging" ? ((this.props.userType === "super-admin" || this.props.userType === "association-admin" || this.props.userType === "association-read-only") && this.state.supportedAssociations.filter(id => id === this.props.associationId).length !== 0) : (this.props.userType === "super-admin" || this.props.userType === "association-admin" || this.props.userType === "association-read-only"))  &&
								<Route path={`${this.props.match.path}/digital-profiles`} component={(props) =>
										<DigitalProfilesAssoc {...props}
											associationId={this.props.associationId}/>
									}/>}
                <Route path={`${this.props.match.path}/alerts`} component={(props) => 
                  <AlertsAssoc {...props}
                    canEditAssociation={this.props.canEditAssociation}
                    associationId={this.props.associationId}
                  />
                  }
                />
                        </Switch>
                    </div>
                </div>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userType: state.user.access.user_type,
	};
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Account);
