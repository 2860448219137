import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import EditAssocAccountModal from '../../../Modals/EditAssocAccountModal';
import EditAddressModal from '../../../Modals/EditAddressModal';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import AssociationService from "../../../../services/api/association";
import UploadImage from "../../../../inputs/UploadImage";
import {connect} from "react-redux";
import {updateAssociation} from "./Actions";
import {bindActionCreators} from "redux";
import moment from "moment";

class PrimaryAssoc extends Component {

    static defaultProps = {
        center: {lat: 40.001869, lng: -75.311804},
        zoom: 10
    };

	constructor() {
		super();
		this.state = {
            EditAccountModalIsOpen: false,
            EditAddressModalIsOpen: false,
			EditAddressModalType: null,
			addresses: [],
			loadingDone: false,
			logo: null,
			clearLogoModalIsOpen:false
        };

        this.openEditAccountModal = this.openEditAccountModal.bind(this);
	}

    openEditAccountModal() {
		this.setState({EditAccountModalIsOpen: true});
    }

    openEditAddressModal(type) {
		this.setState({EditAddressModalIsOpen: true, EditAddressModalType:type});
    }

	closeModal(data) {
		this.setState({
            EditAccountModalIsOpen: false,
            EditAddressModalIsOpen: false,
        });
		if (data) {
			this.props.updateAssociation(this.processAssociationDataFromApi(data));
		}
	}

	processAssociationDataFromApi(data) {
		return {profile: data.association,
			billing_address: data.billing_address,
			billing_contact_address: data.billing_contact_address,
			mailing_address: data.mailing_address,
			mailing_contact_address: data.mailing_contact_address,
			primary_address: data.primary_address,
			primary_contact_address: data.primary_contact_address
		}
	}

	removeLogo(){
		AssociationService.editPrimary(this.props.associationId, {delete_logo: "true", alt_text: ''})
			.then(res => {
				this.setState({clearLogoModalIsOpen: false});
				this.props.updateAssociation(this.processAssociationDataFromApi(res));
			})
			.catch(err=>{console.error(err)});
	}

	saveLogo(file){
		AssociationService.editPrimary(this.props.associationId, {logo: file, 'web-address': this.props.association.profile.web_address, email: this.props.association.profile.email})
			.then(res => {
				this.props.updateAssociation(this.processAssociationDataFromApi(res));
			})
			.catch(err=>{console.error(err)});
	}

	setAltText(data){
		AssociationService.editPrimary(this.props.associationId, {alt_text: data})
			.then(res => {
				this.props.updateAssociation(this.processAssociationDataFromApi(res));
			})
			.catch(err=>{console.error(err)});
	}
  
  showJRLink(link) {
    if (link) {
      let length = link.length
      if ( length > 35)
        return `${link.slice(0,25)}...${link.slice(length-10,length)}`;
    }
    
    return link;
  }

	componentDidMount() {
		AssociationService.getPrimary(this.props.associationId)
			.then(res => {
        this.setState({
          loadingDone: true
        })
				if(res && res.association){
					this.props.updateAssociation(this.processAssociationDataFromApi(res));
				}
			}).catch(error => {
				console.error(error)
			});
	}

	render() {
		return (
            <Fragment>

				{this.state.loadingDone && <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Basic Information</div>
                                <div className="panel__body">
									{/* #559 for now only super admin*/}
									{this.props.super_user && <div className="row">
										<div className="col auto push-right">
											<button onClick={this.openEditAccountModal} className="btn lnk">Edit
											</button>
										</div>
									</div>
									}
                                    <div className="row">
                                        <div className="col jc-fs">
                                            <div className="data-set__container">
                                                <div className="data-set">
                                                    <span className="data-label">Association Number:</span>
                                                    <span>{this.props.association.profile.id}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Association Name:</span>
                                                    <span>{this.props.association.profile.name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Short Name:</span>
                                                    <span>{this.props.association.profile.short_name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Parent Golf Federation:</span>
                                                    <span>{this.props.association.profile.parent_federation ? this.props.association.profile.parent_federation : 'United States Golf Association'}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Phone:</span>
													{this.props.association.profile.telephone && <span>{this.props.association.profile.telephone}</span>}
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Email:</span>
													{this.props.association.profile.email && <span><a href={`mailto:${this.props.association.profile.email}`}>{this.props.association.profile.email}</a></span>}
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Website:</span>
													{this.props.association.profile.web_address && <span><a href={`//${this.props.association.profile.web_address}`} target="_blank" rel="noreferrer">{this.props.association.profile.web_address}</a></span>}
                                                </div>
																								{this.props.join_and_renew_enabled &&
																										<div className="data-set">
																												<span className="data-label">Website Join & Renew Page:</span>
																												{this.props.association.profile.web_address_join_and_renew_page &&
																														<span>
																																<a href={`//${this.props.association.profile.web_address_join_and_renew_page}`}
																																	 target="_blank"
																																	 rel="noreferrer">{ this.showJRLink(this.props.association.profile.web_address_join_and_renew_page)}
																																</a>
																														</span>
																												}
																										</div>
																								}
                                                <div className="data-set">
                                                    <span className="data-label">USGA TM Account Manager:</span>
													{this.props.association.profile.usga_tm_account_manager && <span>{this.props.association.profile.usga_tm_account_manager}</span>}
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Regional Affairs Director:</span>
													{this.props.association.profile.regional_affairs_director && <span>{this.props.association.profile.regional_affairs_director}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col jc-fs">
                                            <div className="data-set__container">
                                                <div className="data-set">
                                                    <span className="data-label">Status:</span>
                                                    <span>{this.props.association.profile.status.charAt(0).toUpperCase() + this.props.association.profile.status.slice(1) }</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Association Creation Date:</span>
                                                    <span>{moment(this.props.association.profile.created_date).format('MM/DD/YYYY')}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Last Updated Date:</span>
                                                    <span>{moment(this.props.association.profile.last_update_date).format('MM/DD/YYYY')}</span>
                                                </div>
												<div className="data-set">
													<span className="data-label">Front End Provider:</span>
													<span>{this.props.association.profile.frontend_provider}</span>
												</div>
                                                <div className="data-set">
                                                    <span className="data-label">Is AGA?:</span>
                                                    <span>{this.props.association.profile.allied_assn ? 'Yes' : 'No'}</span>
                                                </div>
												<div className="data-set">
													<span className="data-label">CR Id:</span>
													<span>{this.props.association.profile.course_rating_id}</span>
												</div>
												{/* not needed now */}
                                                {/*<div className="data-set">*/}
                                                {/*    <span className="data-label">CR License?:</span>*/}
                                                {/*    <span>{this.props.association.profile.course_rating_lic  ? 'Yes' : 'No'}</span>*/}
                                                {/*</div>*/}
                                                {/*<div className="data-set">*/}
                                                {/*    <span className="data-label">HCP License?:</span>*/}
                                                {/*    <span>{this.props.association.profile.handicap_program_lic  ? 'Yes' : 'No'}</span>*/}
                                                {/*</div>*/}
                                                <div className="data-set">
                                                    <span className="data-label">Timezone:</span>
                                                    <span>{this.props.association.profile.timezone}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Is Test Association?:</span>
                                                    <span>{this.props.association.profile.is_test ? 'Yes' : 'No'}</span>
                                                </div>
												{/* not needed now */}
                                                {/*<div className="data-set">*/}
                                                {/*    <span className="data-label">Command User?:</span>*/}
                                                {/*    <span>{this.props.association.profile.command_user  ? 'Yes' : 'No'}</span>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className='row'>
						<div className="col jc-fs">
							<div className="panel">
								<div className="panel__head">
									Addresses
								</div>
								<div className="panel__body">

									<table className="data__table">
										<colgroup>
											<col className="col_golfer-name"/>
											<col className="col_golfer-name"/>
											<col className="col_golfer-name"/>
											<col className="col_edit"/>
										</colgroup>
										<thead>
										<tr>
											<th>Address Type</th>
											<th>Address</th>
											<th>Contact Information</th>
											<th className='col_edit'></th>
										</tr>
										</thead>
										<tbody>
										<tr>
											<td>Primary</td>
											<td>
                        {
                          this.props.association.primary_address ?
                          <span>
    												{this.props.association.primary_address.street_1} {this.props.association.primary_address.street_1 && <br/>}
    												{this.props.association.primary_address.street_2} {this.props.association.primary_address.street_2  && <br/>}
    												{[this.props.association.primary_address.city, this.props.association.primary_address.state_name, this.props.association.primary_address.zip, this.props.association.primary_address.country_name].filter(v => v).join(', ')}
  											  </span>
                          : null
                        }
                      </td>
											<td>
                        {
                          this.props.association.primary_contact_address ?
                          <span>
    												{this.props.association.primary_contact_address.first_name} {this.props.association.primary_contact_address.last_name}<br/>
    												{this.props.association.primary_contact_address.email}{this.props.association.primary_contact_address.email && <br/>}
    												{this.props.association.primary_contact_address.phone}
                          </span>
                          : null
                        }
											</td>
											<td className='col_edit'>
												{this.props.canEditAssociation && <div className="address__head">
													<button onClick={() => this.openEditAddressModal('primary')}
															className="btn lnk">Edit
													</button>
												</div>}
											</td>
										</tr>
										<tr>
											<td>Mailing</td>
											<td>
                        {
                          this.props.association.mailing_address ?
                          <span>
    												{this.props.association.mailing_address.street_1} {this.props.association.mailing_address.street_1 && <br/>}
    												{this.props.association.mailing_address.street_2} { this.props.association.mailing_address.street_2  && <br/>}
    												{[this.props.association.mailing_address.city, this.props.association.mailing_address.state_name, this.props.association.mailing_address.zip, this.props.association.mailing_address.country_name].filter(v => v).join(', ')}
											    </span>
                          : null
                        }
                      </td>
											<td>
                        {
                          this.props.association.mailing_contact_address ?
                          <span>
    												{this.props.association.mailing_contact_address.first_name} {this.props.association.mailing_contact_address.last_name}<br/>
    												{this.props.association.mailing_contact_address.email}{this.props.association.mailing_contact_address.email && <br/>}
    												{this.props.association.mailing_contact_address.phone}
                          </span>
                          : null
                        }
											</td>
											<td className='col_edit'>
												{this.props.canEditAssociation && <div className="address__head">
													<button onClick={() => this.openEditAddressModal('mailing')}
															className="btn lnk">Edit
													</button>
												</div>}
											</td>
										</tr>
										<tr>
											<td>Billing</td>
											<td>
                        {
                          this.props.association.billing_address ?
                          <span>
    												{this.props.association.billing_address.street_1} {this.props.association.billing_address.street_1 && <br/>}
    												{this.props.association.billing_address.street_2} { this.props.association.billing_address.street_2  && <br/>}
    												{[this.props.association.billing_address.city, this.props.association.billing_address.state_name, this.props.association.billing_address.zip, this.props.association.billing_address.country_name].filter(v => v).join(', ')}
                          </span>
                          : null
                        }
                      </td>
											<td>
                        {
                          this.props.association.billing_contact_address ?
                          <span>
    												{this.props.association.billing_contact_address.first_name} {this.props.association.billing_contact_address.last_name}<br/>
    												{this.props.association.billing_contact_address.email}{this.props.association.billing_contact_address.email && <br/>}
    												{this.props.association.billing_contact_address.phone}
                          </span>
                          : null
                        }
											</td>
											<td className='col_edit'>
												{this.props.canEditAssociation && <div className="address__head">
													<button onClick={() => this.openEditAddressModal('billing')}
															className="btn lnk">Edit
													</button>
												</div>}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<UploadImage title={`Association Logo`}
											 readOnly={!this.props.canEditAssociation}
											 image={this.props.association.profile.logo}
											 alt_text={this.props.association.profile.alt_text}
											 minimumHeight={155}
											 minimumWidth={155}
											 square={true}
											 logo={true}
											 onSave={(data) => {this.saveLogo(data)}}
											 onRemove={() => {this.removeLogo()}}
											 onSetAltText={(data) => this.setAltText(data)}
								/>
							</div>
						</div>
					</div>

				</div>}

                    <Modal
						isOpen={this.state.EditAccountModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<EditAssocAccountModal assoc={this.props.association} associationId={this.props.association.profile.id} closeModal={(data)=>{this.closeModal(data)}} join_and_renew_enabled={this.props.join_and_renew_enabled}/>
					</Modal>


					<ConfirmationModal openModal={this.state.clearLogoModalIsOpen}
									   onConfirmAction={()=>this.clearLogo()}
									   onCancelAction={()=>{this.setState({clearLogoModalIsOpen: false})}}
									   closeModal={()=>{this.setState({clearLogoModalIsOpen: false})}}
									   question={"Are you sure?"}
									   cancelLabel={"No"}
									   confirmLabel={"Yes"}/>

                    <Modal
						isOpen={this.state.EditAddressModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<EditAddressModal type={this.state.EditAddressModalType} for={"association"} countries={this.state.countries} states={this.state.states} assoc={this.props.association} closeModal={(data)=>{this.closeModal(data)}} />
					</Modal>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		association: state.association,
		super_user: state.user.access.super_user
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateAssociation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryAssoc);
